import React, { useState, useEffect } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import Typography from "@material-ui/core/Typography"

// ICONS
import CallIcon from "@material-ui/icons/Call"

import GroupAddIcon from "@material-ui/icons/GroupAdd"
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm"
import TimerIcon from "@material-ui/icons/Timer"

import { makeStyles } from "@material-ui/core/styles"
import { grey, blue } from "@material-ui/core/colors"
import { toast } from "react-toastify"

import Chart from "./Chart"
import ButtonWithSpinner from "../../components/ButtonWithSpinner"

import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus"
import { isArray } from "lodash"

import useDashboard from "../../hooks/useDashboard"
import useCompanies from "../../hooks/useCompanies"

import { isEmpty } from "lodash"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        height: 240,
        overflowY: "auto",
        ...theme.scrollbarStyles,
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
    card1: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#0094bb",
        color: "#eee",
    },
    card2: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#748e9d",
        color: "#eee",
    },
    card3: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#7f78e6",
        color: "#eee",
    },
    card4: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#cc991b",
        color: "#eee",
    },
    card5: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#434243",
        color: "#eee",
    },
    card6: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#b87d77",
        color: "#eee",
    },
    card7: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#7bc780",
        color: "#eee",
    },
    card8: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#b05c38",
        color: "#eee",
    },
    card9: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#bd3c58",
        color: "#eee",
    },
}))

const Dashboard = () => {
    const classes = useStyles()
    const [counters, setCounters] = useState({})
    const [attendants, setAttendants] = useState([])
    const [filterType, setFilterType] = useState(1)
    const [period, setPeriod] = useState(0)
    const [companyDueDate, setCompanyDueDate] = useState()
    const [dateFrom, setDateFrom] = useState(
        moment("1", "D").format("YYYY-MM-DD")
    )
    console.log(companyDueDate)
    const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"))
    const [loading, setLoading] = useState(false)
    const { find } = useDashboard()
    const { finding } = useCompanies()
    useEffect(() => {
        async function firstLoad() {
            await fetchData()
        }
        setTimeout(() => {
            firstLoad()
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleChangePeriod(value) {
        setPeriod(value)
    }

    async function handleChangeFilterType(value) {
        setFilterType(value)
        if (value === 1) {
            setPeriod(0)
        } else {
            setDateFrom("")
            setDateTo("")
        }
    }

    async function fetchData() {
        setLoading(true)

        let params = {}

        if (period > 0) {
            params = {
                days: period,
            }
        }

        if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
            params = {
                ...params,
                date_from: moment(dateFrom).format("YYYY-MM-DD"),
            }
        }

        if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
            params = {
                ...params,
                date_to: moment(dateTo).format("YYYY-MM-DD"),
            }
        }

        if (Object.keys(params).length === 0) {
            toast.error("Parametrize o filtro")
            setLoading(false)
            return
        }

        const data = await find(params)

        setCounters(data.counters)
        if (isArray(data.attendants)) {
            setAttendants(data.attendants)
        } else {
            setAttendants([])
        }

        setLoading(false)
    }

    useEffect(() => {
        async function fetchData() {
            await loadCompanies()
        }
        fetchData()
    }, [])
    //let companyDueDate = localStorage.getItem("companyDueDate");
    //const companyDueDate = localStorage.getItem("companyDueDate").toString();
    const companyId = localStorage.getItem("companyId")
    const loadCompanies = async () => {
        setLoading(true)
        try {
            const companiesList = await finding(companyId)
            setCompanyDueDate(
                moment(companiesList.dueDate).format("DD/MM/yyyy")
            )
        } catch (e) {
            console.log("🚀 Console Log : e", e)
            // toast.error("Não foi possível carregar a lista de registros");
        }
        setLoading(false)
    }

    function formatTime(minutes) {
        return moment()
            .startOf("day")
            .add(minutes, "minutes")
            .format("HH[h] mm[m]")
    }

    function renderFilters() {
        if (filterType === 1) {
            return (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>
            )
        } else {
            return (
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="period-selector-label">
                            Período
                        </InputLabel>
                        <Select
                            labelId="period-selector-label"
                            id="period-selector"
                            value={period}
                            onChange={(e) => handleChangePeriod(e.target.value)}
                        >
                            <MenuItem value={0}>Nenhum selecionado</MenuItem>
                            <MenuItem value={3}>Últimos 3 dias</MenuItem>
                            <MenuItem value={7}>Últimos 7 dias</MenuItem>
                            <MenuItem value={15}>Últimos 15 dias</MenuItem>
                            <MenuItem value={30}>Últimos 30 dias</MenuItem>
                            <MenuItem value={60}>Últimos 60 dias</MenuItem>
                            <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        </Select>
                        <FormHelperText>
                            Selecione o período desejado
                        </FormHelperText>
                    </FormControl>
                </Grid>
            )
        }
    }

    return (
        <div>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3} justifyContent="flex-end">
                    {/* ATENDIMENTOS PENDENTES */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            className={classes.card1}
                            style={{ overflow: "hidden" }}
                            elevation={4}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography
                                        component="h3"
                                        variant="h6"
                                        paragraph
                                    >
                                        Atd. Pendentes
                                    </Typography>
                                    <Grid item>
                                        <Typography component="h1" variant="h4">
                                            {counters.supportPending}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <CallIcon
                                        style={{
                                            fontSize: 100,
                                            color: "#0b708c",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* ATENDIMENTOS ACONTECENDO */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            className={classes.card2}
                            style={{ overflow: "hidden" }}
                            elevation={6}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography
                                        component="h3"
                                        variant="h6"
                                        paragraph
                                    >
                                        Atd. Acontecendo
                                    </Typography>
                                    <Grid item>
                                        <Typography component="h1" variant="h4">
                                            {counters.supportHappening}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <HourglassEmptyIcon
                                        style={{
                                            fontSize: 100,
                                            color: "#47606e",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* ATENDIMENTOS REALIZADOS */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            className={classes.card3}
                            style={{ overflow: "hidden" }}
                            elevation={6}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography
                                        component="h3"
                                        variant="h6"
                                        paragraph
                                    >
                                        Finalizados
                                    </Typography>
                                    <Grid item>
                                        <Typography component="h1" variant="h4">
                                            {counters.supportFinished}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <CheckCircleIcon
                                        style={{
                                            fontSize: 100,
                                            color: "#5852ab",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* NOVOS CONTATOS */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            className={classes.card4}
                            style={{ overflow: "hidden" }}
                            elevation={6}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography
                                        component="h3"
                                        variant="h6"
                                        paragraph
                                    >
                                        Novos Contatos
                                    </Typography>
                                    <Grid item>
                                        <Typography component="h1" variant="h4">
                                            {counters.leads}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <GroupAddIcon
                                        style={{
                                            fontSize: 100,
                                            color: "#8c6b19",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* T.M. DE ATENDIMENTO */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            className={classes.card8}
                            style={{ overflow: "hidden" }}
                            elevation={6}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography
                                        component="h3"
                                        variant="h6"
                                        paragraph
                                    >
                                        T.M. de Atendimento
                                    </Typography>
                                    <Grid item>
                                        <Typography component="h1" variant="h4">
                                            {formatTime(
                                                counters.avgSupportTime
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <AccessAlarmIcon
                                        style={{
                                            fontSize: 100,
                                            color: "#7a3f26",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* T.M. DE ESPERA */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            className={classes.card9}
                            style={{ overflow: "hidden" }}
                            elevation={6}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography
                                        component="h3"
                                        variant="h6"
                                        paragraph
                                    >
                                        T.M. de Espera
                                    </Typography>
                                    <Grid item>
                                        <Typography component="h1" variant="h4">
                                            {formatTime(counters.avgWaitTime)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <TimerIcon
                                        style={{
                                            fontSize: 100,
                                            color: "#8a2c40",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* FILTROS */}
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectContainer}>
                            <InputLabel id="period-selector-label">
                                Tipo de Filtro
                            </InputLabel>
                            <Select
                                labelId="period-selector-label"
                                value={filterType}
                                onChange={(e) =>
                                    handleChangeFilterType(e.target.value)
                                }
                            >
                                <MenuItem value={1}>Filtro por Data</MenuItem>
                                <MenuItem value={2}>
                                    Filtro por Período
                                </MenuItem>
                            </Select>
                            <FormHelperText>
                                Selecione o período desejado
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {renderFilters()}

                    {/* BOTAO FILTRAR */}
                    <Grid item xs={12} className={classes.alignRight}>
                        <ButtonWithSpinner
                            loading={loading}
                            onClick={() => fetchData()}
                            variant="contained"
                            color="primary"
                        >
                            Filtrar
                        </ButtonWithSpinner>
                    </Grid>

                    {/* DASHBOARD ATENDIMENTOS HOJE */}
                    <Grid item xs={12}>
                        <Paper className={classes.fixedHeightPaper}>
                            <Chart />
                        </Paper>
                    </Grid>

                    {/* USUARIOS ONLINE */}
                    <Grid item xs={12}>
                        {attendants.length ? (
                            <TableAttendantsStatus
                                attendants={attendants}
                                loading={loading}
                            />
                        ) : null}
                    </Grid>
                </Grid>
            </Container>

            {/* GRID DO VENCIMENTO */}
            {/* <Grid item xs={12} sm={6} md={3}>
            <CardCounter
              icon={<TodayIcon fontSize="inherit" />}
              title="Data Vencimento"
              value={companyDueDate}
              loading={loading}
            />
          </Grid> */}
        </div>
    )
}

export default Dashboard
