import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";

import {
    makeStyles,
    Paper,
    Card,
    CardActions,
    CardContent,
    InputAdornment,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    Select,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
  } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import api from "../../services/api";

import usePlans from "../../hooks/usePlans";
import useSubscribe from "../../hooks/useSubscribe";
import { FileCopy } from "@material-ui/icons";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  card: {
    width: "100%",
    maxWidth: 280,
    padding: "12px 8px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 12
    },
    pos: {
        marginBottom: 12
    },
    fullWidth: {
        width: "100%",
      },
    tableContainer: {
        width: "100%",
        overflowX: "scroll",
        ...theme.scrollbarStyles,
      },
      columnButton: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 6
      }
}));

const ModalSubscription = ({ open, onClose, companyId }) => {
    const { list } = usePlans()
    const { getSubscribeByCompany } = useSubscribe()
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [plans, setPlans] = useState([])
    const [subscribe, setSubscribe] = useState({})
    const [paymentData, setPaymentData] = useState({})
    const [modalLinkPay, setModalLinkPay] = useState(false)
    const [loading, setLoading] = useState(false)

    async function getData() {
        setIsSubmitting(true)
        await Promise.all([
            list(),
            getSubscribeByCompany(companyId)
        ]).then(([plans, subscription]) => {
            setPlans(plans)
            setSubscribe({...subscription.data.subscription, plan: subscription.data.plan})
        })
        setIsSubmitting(false)
    }

    useEffect(() => {
        if (open) {
            getData()
        }
    },[open])

   const cancelSubscription = async () => {
        setIsSubmitting(true)
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Cancelar assinatura?")) {
            try {
                const response = await api.put("/admin/subscription/cancel",{
                    id: subscribe.id
                })
                if(response.status === 200) {
                    toast.success("Inscrição cancelada com sucesso")
                    getData()
                }

            } catch (error) {
                toast.error(error)
            }
        }
        setIsSubmitting(false)
      };


      const assignPlan = async (plan) => {
        setLoading(true)
         // eslint-disable-next-line no-restricted-globals
        if(confirm(`Atribuir o plano ${plan.name} para esta empresa?`)) {
            setIsSubmitting(true)

            const commercialPartner = Number(plan.value) === 0

            try {
            const response = await api.post("/admin/subscription", {
                companyId,
                planId: plan.id,
                commercialPartner: commercialPartner
            });

            if(response.status === 200) {
            setPaymentData(response.data);
                setModalLinkPay(true)

                toast.success(
                    "Assinatura realizada com sucesso!, aguardando a realização do pagamento"
                );

                setIsSubmitting(false)
                getData()
            } else {
                console.log(response.data)
            }
            } catch(e) {
                toast.error(e)
            }
        }
        setLoading(false)
      }

      const handleActivateSubscription = async () => {
        setIsSubmitting(true)
        try {
            const response = await api.put(`/admin/subscription/${subscribe.id}/activate`)
            if(response.status === 200) {
                toast.success("Assinatura ativada com sucesso")
                getData()
            }
        } catch (error) {
            toast.error("Ocorreu um erro ao tentar ativar a assinatura.")
        }
        setIsSubmitting(false)
      }

      const SubscriptionGrid = () => {
        return (
            <Paper className={classes.tableContainer}>
            <Table
              className={classes.fullWidth}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">id</TableCell>
                  <TableCell align="left">Nome</TableCell>
                  <TableCell align="left">Valor</TableCell>
                  <TableCell align="left">Plano custom?</TableCell>
                  <TableCell align="left">Conexões</TableCell>
                  <TableCell align="left">Usuários</TableCell>
                  <TableCell align="left">Filas</TableCell>
                  <TableCell align="left">Selecionar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plans?.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.name || "-"}</TableCell>
                    <TableCell align="left">{parseFloat(row.value).toLocaleString("pt-BR", { style: 'currency', currency: "BRL" }) || "-"}</TableCell>
                    <TableCell align="left">{row.isCustomPlan ? "sim" : "não"}</TableCell>
                    <TableCell align="left">{row.connections || "-"}</TableCell>
                    <TableCell align="left">{row.users || "-"}</TableCell>
                    <TableCell align="left">{row.queues || "-"}</TableCell>
                    <TableCell align="left">
                        <Button variant="contained" color="primary" onClick={() => assignPlan(row)}>Selecionar</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      }

  return (

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={subscribe.id ? "xs" : "md"}
        fullWidth
        scroll="paper"
      >
        <Dialog
            open={modalLinkPay}
            onClose={() => {
                setModalLinkPay(false)
                setPaymentData({})
            }}
            maxWidth="xs"
        >
             <DialogTitle>
                Assinatura efetuada
             </DialogTitle>

             <Typography
                className={classes.pos}
                color="textSecondary"
                align="center"
            >
                Envie o link de pagamento para o cliente. Assim que o pagamento for confirmado, a assinatura será ativada
            </Typography>

            <TextField
                disabled
                value={paymentData.paymentUrl}
                style={{ cursor: "pointer" }}
                variant="outlined"
                onClick={() => {
                    navigator.clipboard.writeText(paymentData.paymentUrl)
                    toast.success("Link copiado para área de transferência");
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        navigator.clipboard.writeText(paymentData.paymentUrl)
                                        toast.success("Link copiado para área de transferência");
                                    }}
                                >
                                    <FileCopy />
                                </IconButton>
                            </InputAdornment>
                        </>
                    )
                }}
            />

            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setModalLinkPay(false)
                        setPaymentData({})
                    }}
                >
                    Fechar
                </Button>
            </CardActions>
        </Dialog>

        <DialogTitle id="form-dialog-title">
            Assinatura
        </DialogTitle>

        {
            !!subscribe?.id ? (
            <>
              <Typography
                    variant="h5"
                    component="h2"
                    align="center"
                    style={{ marginBottom: "1rem" }}
                >
                      {subscribe.status === "new"
                                ? "Pagamento pendente"
                                : subscribe.status === "waitingPayment"
                                ? "Aguardando pagamento"
                                : "Assinatura atual"}
                </Typography>

                <Card className={classes.card} variant="outlined">
                    <CardContent>
                        <Typography
                            variant="h5"
                            component="h2"
                            align="center"
                            style={{ marginBottom: "1rem" }}
                        >
                            {subscribe.plan.name}
                        </Typography>

                        <Typography
                            className={classes.pos}
                            color="textSecondary"
                            align="center"
                        >
                            {Number(subscribe?.plan.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                        </Typography>
                    </CardContent>

                    <CardActions>
                            <div className={classes.columnButton}>
                            {(subscribe.status === "new" && !subscribe.chargeStatus) && (
                                <>
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            if (confirm("Confirmar? Confirme apenas se tiver certeza que o cliente efetuou o pagamento.")) {
                                                handleActivateSubscription()
                                            }
                                        }}
                                        >
                                        Confirmar pagamento
                                    </Button>
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        fullWidth
                                        color="inherit"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            navigator.clipboard.writeText(subscribe.paymentUrl)
                                            toast.success("Link copiado para área de transferência")
                                        }}
                                        >
                                        Copiar link de pagamento
                                    </Button>
                                </>
                            )}
                            <Button
                                size="medium"
                                variant="contained"
                                fullWidth
                                color="secondary"
                                disabled={isSubmitting}
                                onClick={cancelSubscription}
                            >
                                Cancelar assinatura
                            </Button>
                            </div>

                    </CardActions>
                </Card>
            </>
            ) : (
                <>
                    <Typography
                        variant="h5"
                        component="h2"
                        align="center"
                        style={{ marginBottom: "1rem", marginTop: "3rem" }}
                    >
                      Nova assinatura
                     </Typography>
                    <SubscriptionGrid />
              </>
            )
        }

            <DialogActions>
                <ButtonWithSpinner
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                    onClick={onClose}
                    loading={loading || isSubmitting}
                >
                    Fechar
                </ButtonWithSpinner>
            </DialogActions>
      </Dialog>

  );
};

export default ModalSubscription;
